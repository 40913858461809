import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Formulaire from './components/Formulaire';
import queryString from 'query-string';


import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route path='/' render={(props) => <Formulaire {...props} code={queryString.parse(window.location.search).code}></Formulaire>} />
      </Layout>
    );
  }
}
