import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationFR from './assets/locales/fr/translation.json';
import translationEN from './assets/locales/en/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN
            },
            fr: {
                translation: translationFR
            }
        },
        defaultNS: "translation",
        fallbackLng: "en",
        debug: true,
        preload: ['en', 'fr'],
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        }
    })

export default i18n