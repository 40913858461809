import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker'
import './i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

document.head.insertAdjacentHTML('beforeend', '<meta http-equiv="Content-Type" content="application/json; charset=UTF-8" />');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Suspense fallback="Loading">
            <App />
        </Suspense>
    </BrowserRouter>,
  rootElement);

registerServiceWorker();

