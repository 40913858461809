import React, { Component } from 'react';
import PersonIcon from "../assets/icon/user.svg";
import { withTranslation } from 'react-i18next';

class DagesamHeader extends Component {
    
    render() {
        const { t } = this.props;
        return (
            <header className="col-4">
                <img src={PersonIcon} alt="PersonIcon" />
                <h1 className="title">{t('h1_DageSAMHeader')}</h1>
            </header>
        );
    }
} export default withTranslation()(DagesamHeader);