import React, { Component } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import '@progress/kendo-theme-default/dist/all.css';
import Logo from "../assets/img/Logo_Daher.png";
import { withTranslation } from 'react-i18next';
const signalR = require("@microsoft/signalr");
const checkAuthCode = 'CheckAuthCode';
const resetPassword = 'ResetPassword';

class Formulaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmedPassword: '',
            authCodeOK: null,
            samePassword: null,
            hubConnection: null,
            passwordReset: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onUnload = this.onUnload.bind(this);
    }

    componentDidMount() {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/InitPassword")
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        this.setState({ hubConnection }, () => {
            if (this.props.code) {
                this.state.hubConnection.start()
                    .then(() => {
                        console.log('connection started');

                        hubConnection
                            .invoke(checkAuthCode, this.props.code)
                            .catch((err) => {
                                console.error(err);
                                this.setState({ authCodeOK: false });
                            });


                    })
                    .catch(err => console.log('Error while establishing connection :'));
            }

            this.state.hubConnection.on("UserCodeChecked", (codeOK, tempuser) => {
                this.setState({
                    authCodeOK: codeOK
                });
            });
            this.state.hubConnection.on("PasswordReset", (passwordReset) => {
                this.setState({
                    passwordReset: passwordReset
                });
                this.state.hubConnection.stop();
            });
            this.state.hubConnection.on("Test", (string) => {
                console.log(string);
            });
        });
        window.addEventListener('beforeunload', this.onUnload);
    }

    componentWillUnmount() {
        this.state.hubConnection.stop();
        window.removeEventListener('beforeunload', this.onUnload);
    }

    closeDialog() {
        this.setState({ samePassword: null });
    }


    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        if (this.state.authCodeOK) {
            if (this.state.password === this.state.confirmedPassword) {
                if (verifyPassword(this.state.password) && verifyPassword(this.state.confirmedPassword)) {
                    this.setState({ samePassword: true });

                    this.state.hubConnection
                        .invoke(resetPassword, this.state.password, this.props.code)
                        .catch(err => console.error(err));

                }
                else {
                    this.setState({ samePassword: false });
                }
            }
            else {
                this.setState({ samePassword: false });
            }
        }
        event.preventDefault();
    }

    onFocus(event) {
        event.target.parentNode.classList.add('focused');
    }

    onBlur(event) {
        var inputValue = event.target.value;
        if (inputValue === "") {
            if (event.target.classList.contains('valid')) {
                event.target.classList.remove('valid');
            }
            else if (event.target.classList.contains('invalid')) {
                event.target.classList.remove('invalid');
            }
        }
        else {
            if (event.target.id === "password") {
                if (verifyPassword(event.target.value)) {
                    event.target.classList.add('valid');
                }
                else {
                    console.log(this);
                    this.setState({ samePassword: false });
                    event.target.classList.add('invalid');
                }
            }
            else if (event.target.id === "confirmedPassword") {
                if (verifyPassword(event.target.value)) {
                    if (this.state.password === this.state.confirmedPassword) {
                        event.target.classList.add('valid');
                    }
                    else {
                        this.setState({ samePassword: false });
                        event.target.classList.add('invalid');
                    }
                }
                else {
                    this.setState({ samePassword: false });
                    event.target.classList.add('invalid');
                }
            }
        }
    }

    onUnload(event) {
        event.preventDefault();
        this.state.hubConnection.stop();
    }

    render() {
        const { t } = this.props;
        if (this.props.code) {
            if (this.state.authCodeOK) {
                if (this.state.passwordReset) {
                    return (
                        <div className="col container wrapper">
                            <div className="row">
                                <img src={Logo} className="col-2 " alt="logo" />
                            </div>
                            <div className="row">
                                <p className="col">{t('p_DageSAM_InitPassword_Done')}</p>
                            </div>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="col container wrapper" onbeforeunload={this.onUnload}>
                            <div className="row">
                                <img src={Logo} className="col-2 " alt="logo" />
                            </div>
                            <div className="row">
                                <p className="col">{t('p_DageSAM_InitPassword_Change')}</p>
                            </div>
                            <div className="row">
                                <form className="form container" name="password" onSubmit={this.handleSubmit}>
                                    <div className="row justify-content-center">
                                        <div className="form-group col-md-10 col-xs-12">
                                            <label for="password" className="form-label">{t('label_Password')}<span className="required" aria-hidden="true">*</span></label>
                                            <input type="password" name="password" className="form-input form-control" id="password" name="password" onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.password} onChange={this.handleChange} required></input>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="form-group col-md-10 col-xs-12">
                                            <label for="confirmedPassword" className="form-label">{t('label_Confirmed_Password')}<span className="required" aria-hidden="true">*</span></label>
                                            <input type="password" name="confirmedPassword" className="form-input form-control" id="confirmedPassword" name="confirmedPassword" onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.confirmedPassword} onChange={this.handleChange} required></input>
                                        </div>
                                    </div>
                                    <div className="form-group row justify-content-end">
                                        <button name="submit" className="btn btn-primary" onclick={this.handleSubmit} id="submit">{t('button_Send')}</button>
                                    </div>
                                </form>
                            </div>
                            {this.state.samePassword === false && <Dialog title={t('dialog_Password_Different')} onClose={this.closeDialog}>
                                <p>{t('dialog_Text')}</p>
                            </Dialog>}
                        </div>
                    );
                }
            }
            else if (this.state.authCodeOK == null) {
                return (
                    <div className="col container wrapper" onbeforeunload={this.onUnload}>
                        <div className="row">
                            <img src={Logo} className="col-2 " alt="logo" />
                        </div>
                        <div className="row">
                            <p className="col">{t('p_Wait_For_Check')}</p>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className="col container wrapper" onbeforeunload={this.onUnload}>
                        <div className="row">
                            <img src={Logo} className="col-2 " alt="logo" />
                        </div>
                        <div className="row">
                            <p className="col">{t('p_Not_Valid_URL')}</p>
                        </div>
                    </div>
                );
            }
        }
        else {
            return (
                <div className="col container wrapper" onbeforeunload={this.onUnload}>
                    <div className="row">
                        <img src={Logo} className="col-2 " alt="logo" />
                    </div>
                    <div className="row">
                        <p className="col">{t('p_Not_Valid_URL')}</p>
                    </div>
                </div>
            );
        }
    }
}
export default withTranslation()(Formulaire);

function verifyPassword(value) {
    if (value.length >= 8) {
        var verifPassword = 0;

        if (/[A-Z]/.test(value)) {
            verifPassword++;
        }
        if (/[a-z]/.test(value)) {
            verifPassword++;
        }
        if (/\d/.test(value)) {
            verifPassword++;
        }
        if (/\W/.test(value)) {
            verifPassword++;
        }
        if (verifPassword >= 3) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}